<template>
    <div class="wxbind">
        <div v-if="success_status">
            <van-icon name="checked" color="#4C94F6" size="40px" />
            <div>
                微信绑定成功，请用微信扫码登录运营平台。
            </div>
        </div>

        <div v-else>
            <!-- <van-icon name="clear" color="red" size="40px" />
            <div>
                当前微信已绑定其他账号，请勿重复操作。
            </div> -->
        </div>

    </div>
</template>
<script>
import { Dialog, Icon } from "vant";

import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_BASE_API2;
export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [Icon.name]: Icon,
    },
    data() {
        return {
            success_status: 1

        };
    },
    created() {
        this.$store.commit("hideTabbar");
        this.bind();
    },
    methods: {
        bind() {
            let qrCodeUrl = window.location.href;

            let code = qrCodeUrl.split("?")[1].split("=")[1];
            let admin_id = qrCodeUrl.split("?")[2].split("=")[1];
            //   alert(code)
            //   alert(admin_id)
            let params = new FormData();
            params.append("option", "bind");
            params.append("admin_id", admin_id);
            params.append("code", code);
            axios({
                url: "/weixin_bind",
                method: "POST",
                data: params,
            }).then((res) => {
                // alert(JSON.stringify(res))
                if (res.data.status === 0) {
                    this.success_status = 1
                } else {
                    this.success_status = 0
                }
                Dialog.alert({
                    title: "提示",
                    message: res.data.msg,
                }).then(() => {
                    // on close
                });
            });
        },
    },
};
</script>
<style  scoped>
.wxbind {
    text-align: center;
    padding-top: 20%;
    font-size: 28px;
}
</style>