import { render, staticRenderFns } from "./WXBind.vue?vue&type=template&id=61e698fc&scoped=true&"
import script from "./WXBind.vue?vue&type=script&lang=js&"
export * from "./WXBind.vue?vue&type=script&lang=js&"
import style0 from "./WXBind.vue?vue&type=style&index=0&id=61e698fc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61e698fc",
  null
  
)

export default component.exports